<template lang="html">
  <div class="card card-primary card-outline TourList">
    <div class="overlay" v-if="$store.state.loading">
      <i class="fa fa-refresh fa-spin"></i>
    </div>
    <div class="card-header p-2">
      <h3 class="card-title">{{$route.params.category||'漫游'}}管理</h3>
      <div class="card-tools mr-0">
        <div class="input-group input-group-sm">
          <input type="text" class="form-control" placeholder="搜索" v-model="query.key" v-on:change="search()">
          <div class="input-group-append">
            <button type="button" class="btn btn-primary" v-on:click="search()">
              <i class="fas fa-search"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body p-1">
      <div class="mailbox-controls p-0">
        <button class="btn btn-sm btn-primary mx-1" title="添加" @click="Create()"><i class="fa fa-plus"></i></button>
        <button class="btn btn-sm btn-default mx-1" title="刷新" @click="loadData()"><i class="fas fa-sync"></i></button>
        <div v-if="query.userName" style="display:inline-block">
          <div class="input-group input-group-sm ">
            <div class="input-group-prepend">
              <span class="input-group-text">{{query.userName}}</span>
            </div>
            <div class="input-group-prepend">
              <span class="input-group-text" @click="query.userName='';search()"><i class="fas fa-times"></i></span>
            </div>
          </div>
        </div>
        <div class="float-right">
          <div class="dropdown">
            <button class="btn btn-sm btn-default dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              筛选
            </button>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
              <form class="dropdown-item p-1">
                <div class="input-group input-group-sm ">
                  <div class="input-group-prepend">
                    <span class="input-group-text" style="font-weight:700">标签</span>
                  </div>
                  <select class="form-control form-control-sm" v-model:value="query.tags" v-on:change="search()" placeholder="标签">
                    <option :value="null">所有</option>
                    <option v-for="i in tags" v-bind:value="i.name">{{i.name}}</option>
                  </select>
                </div>
              </form>
              <form class="dropdown-item p-1">
                <div class="input-group input-group-sm" @dblclick="query.beginTime=null;search()">
                  <div class="input-group-prepend">
                    <span class="input-group-text" style="font-weight:700">起始</span>
                  </div>
                  <input type="date" class="form-control form-control-sm" v-model="query.beginTime" v-on:change="search()">
                </div>
              </form>
              <form class="dropdown-item p-1">
                <div class="input-group input-group-sm" @dblclick="query.endTime=null;search()">
                  <div class="input-group-prepend">
                    <span class="input-group-text" style="font-weight:700">结束</span>
                  </div>
                  <input type="date" class="form-control form-control-sm" v-model="query.endTime" v-on:change="search()">
                </div>
              </form>
              <form class="form-control-sm text-nowrap p-1">
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" id="customRadioHasGps1" name="customRadioHasGps" class="custom-control-input" v-model="query.hasGps" :value="true" v-on:change="search()">
                  <label class="custom-control-label" for="customRadioHasGps1">有GPS</label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" id="customRadioHasGps2" name="customRadioHasGps" class="custom-control-input" v-model="query.hasGps" :value="false" v-on:change="search()">
                  <label class="custom-control-label" for="customRadioHasGps2">无GPS</label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" id="customRadioHasGps3" name="customRadioHasGps" class="custom-control-input" v-model="query.hasGps" :value="null" v-on:change="search()">
                  <label class="custom-control-label" for="customRadioHasGps3">不限</label>
                </div>
              </form>
              <form class="form-control-sm text-nowrap p-1">
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" id="customRadioPrivate1" name="customRadioPrivate" class="custom-control-input" v-model="query.private" :value="true" v-on:change="search()">
                  <label class="custom-control-label" for="customRadioPrivate1">草稿</label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" id="customRadioPrivate2" name="customRadioPrivate" class="custom-control-input" v-model="query.private" :value="false" v-on:change="search()">
                  <label class="custom-control-label" for="customRadioPrivate2">公开</label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" id="customRadioPrivate3" name="customRadioPrivate" class="custom-control-input" v-model="query.private" :value="null" v-on:change="search()">
                  <label class="custom-control-label" for="customRadioPrivate3">不限</label>
                </div>
              </form>
              <form class="form-control-sm text-nowrap p-1">
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" id="customRadioFine1" name="customRadioFine" class="custom-control-input" v-model="query.fine" :value="true" v-on:change="search()">
                  <label class="custom-control-label" for="customRadioFine1">精华</label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" id="customRadioFine2" name="customRadioFine" class="custom-control-input" v-model="query.fine" :value="false" v-on:change="search()">
                  <label class="custom-control-label" for="customRadioFine2">普通</label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" id="customRadioFine3" name="customRadioFine" class="custom-control-input" v-model="query.fine" :value="null" v-on:change="search()">
                  <label class="custom-control-label" for="customRadioFine3">不限</label>
                </div>
              </form>
              <form class="form-control-sm text-nowrap p-1">
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" id="customRadioClosed1" name="customRadioClosed" class="custom-control-input" v-model="query.closed" :value="true" v-on:change="search()">
                  <label class="custom-control-label" for="customRadioClosed1">屏蔽</label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" id="customRadioClosed2" name="customRadioClosed" class="custom-control-input" v-model="query.closed" :value="false" v-on:change="search()">
                  <label class="custom-control-label" for="customRadioClosed2">打开</label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" id="customRadioClosed3" name="customRadioClosed" class="custom-control-input" v-model="query.closed" :value="null" v-on:change="search()">
                  <label class="custom-control-label" for="customRadioClosed3">不限</label>
                </div>
              </form>
              <!--<form class="dropdown-item p-1">
                  <div class="custom-control custom-switch">
                      <input type="checkbox" class="custom-control-input" id="customSwitchPrivate" v-model="query.private" v-on:change="search()">
                      <label class="custom-control-label" for="customSwitchPrivate">私有</label>
                  </div>
              </form>
              <form class="dropdown-item p-1">
                  <div class="custom-control custom-switch">
                      <input type="checkbox" class="custom-control-input" id="customSwitchFine" v-model="query.fine" v-on:change="search()">
                      <label class="custom-control-label" for="customSwitchFine">精华</label>
                  </div>
              </form>-->
            </div>
          </div>
        </div>
        <!-- /.pull-right -->
        <ul class="nav nav-tabs nav-nowrap my-1">
          <li class="nav-item">
            <router-link :class="{'nav-link':true,'active':query.state==null}" :to="{  query: {...$route.query,page:undefined, state: undefined }}">
              全部
            </router-link>
          </li>
          <li v-for="i in status" class="nav-item">
            <router-link :class="{'nav-link':true,'active':query.state==i.value}" :to="{ query: {...$route.query,page:undefined, state: i.value }}">
              {{i.name}}
            </router-link>
          </li>
        </ul>
      </div>
      <div class="row">
        <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3" v-for="i in list">
          <div class="card card-widget widget-user-2">
            <!-- Add the bg color to the header using any of the bg-* classes -->
            <div class="widget-user-header bg-yellow" style="height: 150px;" v-bind:style="resizeimg(i.firstImage?i.firstImage.url:'')" @click="$router.push({ name: 'BlogView', params: { tourId: i.guid }})">
              <!-- /.widget-user-image -->
              <h4 class="">{{i.title}}</h4>
              <h5 class="" @click.stop="query.userName=i.user.userName;search()" style="cursor:pointer">{{i.user.nickName||i.user.userName}}</h5>
              <h6 v-if="i.editor" class="text-truncate">
                <span v-if="i.editor.userName != currentUser.userName">
                  <i class="fas fa-user-edit"></i> {{i.editor.nickName||i.editor.userName}}
                </span>
              </h6>
              <div class="widget-info px-2">
                <h6 class="text-right">
                  <template v-if="i.expiryTime">
                    <time v-if="new Date(i.expiryTime)>new Date()" class="text-shadow text-lime" :title="i.expiryTime">Pro 功能 {{$moment(i.expiryTime).fromNow()}}过期</time>
                    <time v-else class="text-shadow text-orange" :title="i.expiryTime">Pro 功能 {{$moment(i.expiryTime).format("YYYY-MM-DD HH:mm")}} 已过期</time>
                  </template>
                </h6>
                <h6 class="">
                  <span title="观看"><i class="fas fa-eye"></i> {{i.viewCount}}</span>
                  <span title="点赞"><i class="fas fa-thumbs-up"></i> {{i.praiseCount}}</span>
                  <span><i class="fas fa-map-marker-alt" title="地图坐标" v-if="i.gpsLat!=0 || i.gpsLng!=0"></i></span>
                  <span>{{i.status}}</span>
                  <span v-if="i.private">草稿</span>
                  <span v-if="i.fine">精</span>
                  <span v-if="i.featured"><i class="fas fa-star"></i></span>
                  <span>{{i.checkNote}}</span>
                  <time class="float-right" :title="i.addTime">{{$moment(i.addTime).fromNow()}}</time>
                </h6>
              </div>
            </div>
            <div class="card-footer p-0">
              <ul class="nav nav-stacked">
                <li class="">
                  <!-- <button class="btn btn-sm btn-default m-1">
                    <i class="fa fa-qrcode"></i>
                  </button> -->
                  <button class="btn btn-sm btn-default m-1" title="留言管理" @click="$router.push({ name: 'CommentList', query: { articleGuid: i.guid }})">
                    <i class="fa fa-comment-alt"></i>
                  </button>
                  <button class="btn btn-sm btn-warning m-1" title="审核" @click="showDialog(i)">
                    审核
                  </button>
                  <button class="btn btn-sm btn-danger m-1" title="删除" @click="delDialog(i)">
                    删除
                  </button>
                  <button class="btn btn-sm btn-warning m-1" title="修改" @click="$router.push({ name: 'PublicTourEdit', params: { tourId: i.guid }})">
                    修改
                  </button>
                  <a class="btn btn-sm btn-primary m-1" title="分享" :href="setting.siteUrl+'/T/'+i.key" target="_blank">
                    <i class="fas fa-share-square"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer p-2">
      <paging :page-index="pager.pageIndex"
              :total="pager.totalRecords"
              :page-size="pager.pageSize"
              :per-pages="3"
              :show-go="true"
              :prePageSize="pager.prePageSize"
              :onPagerChange="pageChange">
      </paging>
    </div>
    <Modal ref="checkModal">
      <template slot="header">
        <h4 class="modal-title" id="checkModalLabel">作品审核</h4>
      </template>
      <h4 v-text="item.title"></h4>
      <div id='example-3'>

        <!--<input type="checkbox" id="jack" value="Jack" v-model="checkedNames">
        <label for="jack">标题不合规范</label>
        <br>
        <input type="checkbox" id="john" value="John" v-model="checkedNames">
        <label for="john">内容不合规范</label>
        <br>
        <input type="checkbox" id="mike" value="Mike" v-model="checkedNames">
        <label for="mike">全景图不合规范</label>
        <br>
        <span>Checked names: {{ checkedNames }}</span>-->
      </div>
      <template slot="footer">
        <button type="button" class="btn btn-default" data-dismiss="modal">关闭</button>
        <div>
          <button v-if="!item.private&&!item.fine" type="button" class="btn btn-warning mx-1" @click="fine(item)" :disabled="updateing">设精</button>
          <button v-if="item.fine" type="button" class="btn btn-warning mx-1" @click="unFine(item)" :disabled="updateing">取精</button>
          <button v-if="!item.closed" type="button" class="btn btn-danger mx-1" @click="close(item)" :disabled="updateing">屏蔽</button>
          <button v-else type="button" class="btn btn-danger mx-1" @click="unClose(item)" :disabled="updateing">取消屏蔽</button>
          <button v-if="!item.private&&item.checked!=true" type="button" class="btn btn-primary mx-1" @click="check(item)" :disabled="updateing">收录</button>
          <button v-if="item.checked==true" type="button" class="btn btn-primary mx-1" @click="unCheck(item)" :disabled="updateing">撤销收录</button>
        </div>
      </template>
    </Modal>
    <Modal ref="delModal">
      <template slot="header">
        <h4 class="modal-title">删除全景</h4>
      </template>
      是否删除
      <template slot="footer">
        <button type="button" class="btn btn-default" data-dismiss="modal">取消</button>
        <button type="button" class="btn btn-primary" @click="del(item)">确定</button>
      </template>
    </Modal>
  </div>
</template>

<script>
  import { Modal } from 'common'
  import { mapGetters, mapActions, mapState } from 'vuex'

  export default {
    data() {
      return {
        item: {},
        checkedNames: [],
        publicTags: [],
        privateTags: [],
        status: [
          { name: '待收录', value: 0 },
          { name: '未收录', value: 1 },
          { name: '已收录', value: 2 },
        ],
        pager: {
          prePageSize: [12, 24, 48, 96],
          pageIndex: 1,
          pageSize: 1,
          totalPages: 0,
          totalRecords: 0
        },
        query: { ...this.getDefaultQuery(), ...this.$route.query },
        files: [],
        progress: '0%',
        showProgress: false,
        showProgressErro: false,
        ProgressErro: '',
        fileList: [],
        param: {},
        list: null,
        updateing: false,
      }
    },
    inject: ['layoutData'],
    created() {
      this.loadData()
      //this.$store.state.pageinfo.main.title = "用户管理"
      //this.setPageInfo("用户管理")

    },
    computed: {
      ...mapGetters([
        'setting'
      ]),
      tags: function () {
        return [...this.publicTags, ...this.privateTags]
      },
      groupId() {
        return this.layoutData.groupId
      }
    },
    methods: {
      loadData() {
        this.loadTags()
        var url = window.global.ENABLE_ELASTICSEARCH ? `/Api/ES/GroupAdmin/Tours/${this.groupId}` : `/Api/GroupAdmin/Tours/${this.groupId}`;
        this.$axios.get(url, {
          params: {
            ...this.query,
            category: this.$route.params.category,
          }
        }).then((res) => {
          this.pager.pageIndex = res.data.pageIndex;
          this.pager.pageSize = res.data.pageSize;
          this.pager.totalPages = res.data.totalPages;
          this.pager.totalRecords = res.data.totalRecords;
          this.list = res.data.data;
        })
      },
      loadTags() {
        var tagtype = 12;
        this.$axios.get(`/Api/GroupAdmin/Tags/${this.groupId}/All`, { params: { type: tagtype } })
          .then((response) => {
            //console.log(response)
            this.privateTags = response.data
          })
          .catch((error) => {
            console.log(error)
          })
        this.$axios.get('/Api/Tags/All', { params: { type: tagtype } })
          .then((response) => {
            //console.log(response)
            this.publicTags = response.data
          })
          .catch((error) => {
            console.log(error)
          })
      },
      getDefaultQuery() {
        return {
          page: 1,
          pageSize: 12,
          sort: "id",
          order: "desc",
          fields: null,
          key: null,
          tags: null,
          state: null,
          private: null,
          fine: null,
          featured: null,
          closed: null,
          hasGps: null,
          beginTime: null,
          endTime: null,
          userName: '',
        }
      },
      getDifferentQuery(query) {
        var dif = {}
        var all = { ...this.query, ...query }
        var def = this.getDefaultQuery()
        for (var i in all) {
          if (def[i] != all[i]) {
            dif[i] = all[i]
          }
        }
        return dif
      },
      setQuery(newquery) {
        if (!newquery) {
          this.query = this.getDefaultQuery()
        }
        var dif = this.getDifferentQuery(newquery)
        console.log(this.$route.query, dif)
        if (!this.$isObjectValueEqual(this.$route.query, dif)) {
          this.$router.push({ query: dif })
        }
      },
      search() {
        this.setQuery({ page: 1 })
      },
      pageChange(page, pageSize) {
        this.setQuery({ page, pageSize })
      },
      sortingChanged(ctx) {
        this.setQuery({ sort: ctx.sortBy, order: ctx.sortDesc ? "desc" : "asc" })
      },
      resizeimg(url) {
        return {
          'background-image': 'url(' + url.replace('/0/0/0/0/', '/600/0/0/0/') + ')',
          'background-size': 'cover',
          'background-position': 'center',
          'background-repeat': 'no-repeat',

        }
      },
      Create() {
        this.$router.push({ name: 'PublicTourCreate' })
      },
      delDialog(item) {
        this.$refs.delModal.show()
        this.item = item
      },
      showDialog(item) {
        this.$refs.checkModal.show()
        this.item = item
      },
      del(item) {
        let self = this
        var url = `/Api/GroupAdmin/Tours/${this.groupId}/${item.guid}`
        this.$axios.delete(url)
          .then((response) => {
            console.log('#', response)
            self.loadData()
            this.$refs.delModal.hide()
          }).catch((error) => {
            console.log(error)
          })
      },
      check(item) {
        var _this = this
        if (this.updateing) {
          return
        }
        this.updateing = true
        this.$axios.post(`/Api/Admin/Tours/Check/${item.guid}`, { check: true }).then(function (res) {
          if (res.status == 200) {
            _this.$message({
              message: '收录成功',
              type: 'info',
              zIndex: 9999,
            })
            _this.search()
            _this.$refs.checkModal.hide()
            _this.updateing = false
          }
        })
      },
      unCheck(item) {
        var _this = this
        if (this.updateing) {
          return
        }
        this.updateing = true
        this.$axios.post(`/Api/Admin/Tours/UnCheck/${item.guid}`).then(function (res) {
          if (res.status == 200) {
            _this.$message({
              message: '撤销收录成功',
              type: 'info',
              zIndex: 9999,
            })
            _this.search()
            _this.$refs.checkModal.hide()
            _this.updateing = false
          }
        })
      },
      fine(item) {
        var _this = this
        if (this.updateing) {
          return
        }
        this.updateing = true
        this.$axios.get(`/Api/Admin/Tours/Fine/${item.guid}`).then(function (res) {
          if (res.status == 200) {
            _this.$message({
              message: '设置精华成功',
              type: 'info',
              zIndex: 9999,
            })
            _this.search()
            _this.$refs.checkModal.hide()
            _this.updateing = false
          }
        })
      },
      unFine(item) {
        var _this = this
        if (this.updateing) {
          return
        }
        this.updateing = true
        this.$axios.get(`/Api/Admin/Tours/UnFine/${item.guid}`).then(function (res) {
          if (res.status == 200) {
            _this.$message({
              message: '取消精华成功',
              type: 'info',
              zIndex: 9999,
            })
            _this.search()
            _this.$refs.checkModal.hide()
            _this.updateing = false
          }
        })
      },
      close(item) {
        var _this = this
        if (this.updateing) {
          return
        }
        this.updateing = true
        this.$axios.get(`/Api/Admin/Tours/Close/${item.guid}`).then(function (res) {
          if (res.status == 200) {
            _this.$message({
              message: '设置屏蔽成功',
              type: 'info',
              zIndex: 9999,
            })
            _this.search()
            _this.$refs.checkModal.hide()
            _this.updateing = false
          }
        })
      },
      unClose(item) {
        var _this = this
        if (this.updateing) {
          return
        }
        this.updateing = true
        this.$axios.get(`/Api/Admin/Tours/UnClose/${item.guid}`).then(function (res) {
          if (res.status == 200) {
            _this.$message({
              message: '取消屏蔽成功',
              type: 'info',
              zIndex: 9999,
            })
            _this.search()
            _this.$refs.checkModal.hide()
            _this.updateing = false
          }
        })
      },
    },
    components: {
      Modal
    },
    beforeRouteEnter(to, from, next) {
      next(vm => {
        vm.query = { ...vm.getDefaultQuery(), ...to.query }
        setTimeout(vm.loadData, 1)
      })
    },
    beforeRouteUpdate(to, from, next) {
      this.query = { ...this.getDefaultQuery(), ...to.query }
      setTimeout(this.loadData, 1)
      next()
    },
    //beforeRouteLeave(to, from, next) {
    //  // 导航离开该组件的对应路由时调用
    //  // 可以访问组件实例 `this`
    //  $('.modal').modal('hide')
    //  next()
    //}
  }
</script>

<style lang="css">
  .TourList .card-widget .widget-user-header h4 {
    color: #fff;
    text-shadow: 0 0 2px #000;
    font-size: 1rem;
  }

  .TourList .card-widget .widget-user-header h5 {
    color: #fff;
    text-shadow: 0 0 2px #000;
    font-size: 0.8rem;
  }

  .TourList .card-widget .widget-user-header h6 {
    color: #fff;
    text-shadow: 0 0 2px #000;
    font-size: 0.7rem;
  }

  .TourList .card-widget .widget-user-header {
    position: relative;
  }

    .TourList .card-widget .widget-user-header .widget-info {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }

      .TourList .card-widget .widget-user-header .widget-info span {
        padding: 0 0.2rem;
      }
</style>
